import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'mp-file-upload-button',
  standalone: true,
  templateUrl: './file-upload-button.component.html',
  styleUrls: ['./file-upload-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, MatButtonModule],
})
export class FileUploadButtonComponent {
  @Input() disabled = false;

  @Input() supportedFileTypeExtensions = '*';

  @Output() readonly fileUpload: EventEmitter<FileList> = new EventEmitter<FileList>();

  onFileUpload(event: Event): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const files: FileList | null = target.files;

    if (files) {
      this.fileUpload.emit(files);
    }

    // NOTE: This is done to ensure that onChange event will be triggered if user will select the same file
    target.value = '';
  }
}
