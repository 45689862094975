import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { INPUT_SUPPORTED_FILE_TYPE_EXTENSIONS } from '@mp/shared/helper-links/zendesk-support-dialog/util';

import { FileUploadButtonComponent } from '../file-upload-button/file-upload-button.component';

import { AttachmentTypeIconPipe } from './attachment-type-icon.pipe';
import { SupportMessageAttachmentsPresenter } from './support-message-attachments.presenter';

@Component({
  selector: 'mp-support-message-attachments',
  standalone: true,
  templateUrl: './support-message-attachments.component.html',
  styleUrls: ['./support-message-attachments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgFor, MatIconModule, MatButtonModule, AttachmentTypeIconPipe, FileUploadButtonComponent],
  providers: [
    SupportMessageAttachmentsPresenter,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SupportMessageAttachmentsComponent),
      multi: true,
    },
  ],
})
export class SupportMessageAttachmentsComponent implements ControlValueAccessor {
  @Input() maxAttachmentsNumber!: number;

  @Input() maxFileSize!: number;

  protected attachments!: File[];

  readonly INPUT_SUPPORTED_FILE_TYPE_EXTENSIONS: string = INPUT_SUPPORTED_FILE_TYPE_EXTENSIONS;

  constructor(
    private readonly presenter: SupportMessageAttachmentsPresenter,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  addAttachment(fileList: FileList): void {
    const filesArray: File[] = Array.from(fileList);
    const attachmentsRemainingLimit: number = this.maxAttachmentsNumber - this.attachments.length;

    if (!this.presenter.validateAttachments(filesArray, this.maxFileSize, attachmentsRemainingLimit)) {
      return;
    }

    this.attachments = [...this.attachments, ...filesArray];
    this.onChange(this.attachments);
  }

  deleteAttachment(selectedAttachment: File): void {
    this.attachments = this.attachments.filter((attachment) => attachment !== selectedAttachment);
    this.onChange(this.attachments);
  }

  writeValue(attachments: File[]): void {
    this.attachments = attachments;

    this.cdr.markForCheck();
  }

  onChange = (_value: File[]) => {};

  onTouched = () => {};

  registerOnChange(onChange: (value: File[]) => void) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  trackByFn(index: number): number {
    return index;
  }
}
