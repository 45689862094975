<h2 class="support-dialog-title" mat-dialog-title>Support Ticket erstellen</h2>

<button mat-icon-button mat-dialog-close class="support-dialog-close-button">
  <mat-icon class="material-icons-outlined">cancel</mat-icon>
</button>

<div mat-dialog-content class="support-dialog-content">
  <div class="support-dialog-content__description">
    Teile uns deine Frage mit oder schildere uns dein Problem. Wir sind bemüht, schriftliche Anfragen umgehend,
    spätestens innerhalb eines Werktages zu bearbeiten.
  </div>

  <div class="support-dialog-content__description">
    In dringenden Fällen empfehlen wir, eine dringliche Anfrage zu erstellen.
  </div>

  <div class="support-dialog-content__form" [formGroup]="zendeskSupportForm">
    <mp-support-message-priority-selector formControlName="priority" />

    <mat-form-field appearance="fill">
      <mat-label>Betreff</mat-label>
      <input matInput formControlName="subject" />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Nachricht</mat-label>
      <textarea matInput formControlName="body" cdkAutosizeMinRows="4" cdkTextareaAutosize></textarea>
    </mat-form-field>

    <div class="support-dialog-content__section-title">
      Dateianhang
      <mat-icon
        class="support-dialog-content__attachments-info-icon"
        [matTooltip]="attachmentsInfoTooltipText"
        matTooltipPosition="above"
        matTooltipClass="mp-zendesk-support-form-dialog__attachments-info-tooltip"
      >
        info
      </mat-icon>
    </div>

    <mp-support-message-attachments
      formControlName="attachments"
      [maxAttachmentsNumber]="MAX_ATTACHMENTS_NUMBER"
      [maxFileSize]="MAX_FILE_SIZE"
    />

    <button
      class="support-dialog-content__send-button"
      *ngrxLet="ticketSendInProgress$ as ticketSendInProgress"
      mat-flat-button
      type="button"
      [disabled]="zendeskSupportForm.invalid || ticketSendInProgress"
      color="accent"
      (click)="onZendeskSupportMessageSend()"
    >
      <mat-icon>send</mat-icon>
      Senden
    </button>
  </div>

  <mp-support-additional-info label="Eingeloggt als:" class="highlighted">
    {{ data.mailSenderDetails.userFirstName }} {{ data.mailSenderDetails.userLastName }} -
    {{ data.mailSenderDetails.userEmail }}
  </mp-support-additional-info>

  <mp-support-additional-info label="Geschäftszeiten:"> Mo. - Fr. 8:00 - 16:00 Uhr</mp-support-additional-info>
</div>
