<div class="support-message-attachments" *ngFor="let attachment of attachments; trackBy: trackByFn">
  <mat-icon class="support-message-attachments__attachment-icon material-icons-outlined">
    {{ attachment.name | mpAttachmentTypeIcon }}
  </mat-icon>

  <span class="support-message-attachments__attachment-name truncate" [title]="attachment.name">
    {{ attachment.name }}
  </span>

  <button mat-icon-button (click)="deleteAttachment(attachment)">
    <mat-icon class="material-icons-outlined">delete</mat-icon>
  </button>
</div>

<mp-file-upload-button
  [disabled]="attachments.length >= maxAttachmentsNumber"
  [supportedFileTypeExtensions]="INPUT_SUPPORTED_FILE_TYPE_EXTENSIONS"
  class="support-dialog-content__file-upload-button"
  (fileUpload)="addAttachment($event)"
/>
