<button
  class="zendesk-feedback-button"
  [class]="{ 'zendesk-feedback-button--small': isSmall }"
  (click)="openZendeskDialog()"
  matTooltip="Sende uns Feedback!"
  color="accent"
  mat-button
>
  <mat-icon class="zendesk-feedback-button__icon">rate_review</mat-icon>
  <span class="zendesk-feedback-button__label">Rückmeldung</span>
</button>
