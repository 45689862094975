import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { QuillViewHTMLComponent } from 'ngx-quill';

import { ListNewsItem } from '@mp/shared/kernel/newsfeed/domain';

import { NewsItemModificationInfoComponent } from '../news-item-modification-info/news-item-modification-info.component';

@Component({
  selector: 'mp-news-item',
  standalone: true,
  templateUrl: './news-item.component.html',
  styleUrls: ['./news-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,

    MatButtonModule,
    MatIconModule,
    MatExpansionModule,

    NewsItemModificationInfoComponent,
    QuillViewHTMLComponent,
  ],
})
export class NewsItemComponent {
  @HostBinding() readonly class = 'mp-news-item';

  @Input() newsItem!: ListNewsItem;

  @Input() expanded = false;

  @Output() readonly expandedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() readonly editModeChange: EventEmitter<void> = new EventEmitter<void>();

  onExpandedChange(isExpanded: boolean): void {
    this.expandedChange.emit(isExpanded);
  }

  switchToEditMode(): void {
    this.editModeChange.emit();
  }
}
