<div class="news-edit-actions">
  <button
    mat-raised-button
    color="accent"
    (click)="onSave()"
    [disabled]="disabled"
    [matTooltip]="tooltipText"
    matTooltipPosition="above"
    [matTooltipDisabled]="!disabled"
  >
    <mat-icon class="news-edit-actions__save-action-button-icon">done</mat-icon>
    <span class="news-edit-actions__save-action-button-text">Speichern</span>
  </button>

  <button mat-raised-button class="news-edit-actions__close-action-button" (click)="onClose()">
    <mat-icon class="news-edit-actions__close-icon">close</mat-icon>
  </button>

  <button
    *ngIf="showDeleteAction"
    mat-raised-button
    class="news-edit-actions__delete-action-button"
    (click)="onDelete()"
  >
    <mat-icon class="news-edit-actions__delete-icon">delete</mat-icon>
  </button>
</div>
