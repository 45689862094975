<span class="help-center-actions-title">Deine Frage kann damit nicht beantwortet werden?</span>

@if (helpCenterLink()) {
  <a mat-button [href]="helpCenterLink()" color="accent" target="_blank">
    <mat-icon>support</mat-icon>
    Hilfe Center öffnen
  </a>
}

<ng-content />
