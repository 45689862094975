<a
  class="feedback-mail-to-button"
  [ngClass]="{ 'feedback-mail-to-button--small': isSmall }"
  [href]="feedbackEmailSettings | mpFeedbackMailToHref: mailSenderDetails"
  matTooltip="Sende uns Feedback!"
  target="_blank"
  rel="noopener noreferrer"
  color="accent"
  mat-button
>
  <mat-icon class="feedback-mail-to-button__icon">chat</mat-icon>
  <span class="feedback-mail-to-button__label">Rückmeldung</span>
</a>
