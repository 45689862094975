import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

import { UtilPipesModule } from '@core/shared/util';

@Component({
  selector: 'mp-zendesk-help-center-article-card',
  standalone: true,
  templateUrl: './zendesk-help-center-article-card.component.html',
  styleUrls: ['./zendesk-help-center-article-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatCardModule, MatButtonModule, MatIconModule, UtilPipesModule],
})
export class ZendeskHelpCenterArticleCardComponent {
  @Input({ required: true }) articleTitle!: string;

  @Input({ required: true }) articleHTMLDescription!: string;

  @Input({ required: true }) articleLink!: string;
}
