import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'mp-zendesk-help-center-flyout-actions',
  standalone: true,
  templateUrl: './zendesk-help-center-flyout-actions.component.html',
  styleUrl: './zendesk-help-center-flyout-actions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatIconModule],
})
export class ZendeskHelpCenterFlyoutActionsComponent {
  helpCenterLink: InputSignal<string | null> = input.required<string | null>();
}
