import { ChangeDetectionStrategy, Component, OutputEmitterRef, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'mp-create-news-bar',
  standalone: true,
  templateUrl: './create-news-bar.component.html',
  styleUrl: './create-news-bar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatCardModule, MatIconModule],
})
export class CreateNewsBarComponent {
  readonly createNewsClick: OutputEmitterRef<void> = output<void>();

  onCreateNewsClick(): void {
    this.createNewsClick.emit();
  }
}
